import {useEffect} from "react";
import {FilterInspect, Footer, Navbar} from "../../components";
import Body from "../body/Body";
import styles from "./Home.module.scss";
import {RootState, useAppDispatch} from "../../store/store";
import {fetchEntities} from "../../store/reducers/filters";
import {useSelector} from "react-redux";
import {CircularProgress} from "@mui/material";

const Home = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchInitials = async () => {
      await dispatch(fetchEntities());
    };
    fetchInitials();
  }, []);

  const {isLoading} = useSelector((state: RootState) => state.filters);

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={`${styles.header}`} key={`home-navbar`}>
          <Navbar />
        </div>
        <div className={`${styles.content}`} key={`home-body`}>
          <div className={`${styles.bodyContent}`}>
            {isLoading ? (
              <CircularProgress className="loading-circle" />
            ) : (
              <>
                <FilterInspect key={"Filter-inspect-component"} />
                <Body key={"Body-component"} />
              </>
            )}
          </div>
        </div>
        <div className={`${styles.footer}`} key={`home-footer`}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;
