import {useEffect, ChangeEvent} from "react";
import {Button, Input, Select, ThemeProvider} from "@vacasa/react-components-lib";
import styles from "./FilterInspect.module.scss";
import {RootState, useAppDispatch} from "../../store/store";
import {fetchEntities, setSelectedEntity, setSelectedId, setSelectedRoute, setSelectedVersion} from "../../store/reducers/filters";
import {useSelector} from "react-redux";
import {ActionRouteTypes} from "../../types";
import {useNavigate} from "react-router-dom";
import {fetchInspectValuesAction} from "../../store/reducers/changes";
import {NavRoutes} from "../../types/Routes";

export default function FilterInspect(props: any) {
  const navigate = useNavigate();

  const {entities, versions, selectedEntity, selectedId, routeSelected, isInspectDisabled, selectedVersion} = useSelector(
    (state: RootState) => state.filters
  );

  const dispatch = useAppDispatch();

  const onChangeId = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedId(e.target.value));
  };

  const onChangeSelectedEntity = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedEntity(e.target.value));
  };
  const onChangeSelectedVersion = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedVersion(e.target.value));
  };

  const onChangeSelectedFilterRoute = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedRoute(e.target.value));
  };

  const onClickInspect = () => {
    navigate(NavRoutes.INSPECT);
    const fetchInspectValues = async () => {
      await dispatch(fetchInspectValuesAction());
    };
    fetchInspectValues();
  };

  const onClickApply = () => {
    navigate(routeSelected || "");
  };

  const routeOptions = Object.values(ActionRouteTypes);

  return (
    <div className={`${styles.inlineContainer} ${styles.inspectFilterMenu}`}>
      <div className={`${styles.inlineContainer} ${styles.inspectFilters}`}>
        <Select
          key={`FM-Select-entity`}
          label="Data type"
          value={selectedEntity}
          onChange={onChangeSelectedEntity}
          options={entities}
          multiple={false}
          customClass={styles.entitySelect}
        />
        <Select
          key={`FM-Select-version-entity`}
          label="Version"
          value={selectedVersion}
          onChange={onChangeSelectedVersion}
          options={versions}
          multiple={false}
          customClass={styles.entitySelect}
        />
        <Input label="ID" value={selectedId} onChange={onChangeId} disabled={false} type={"text"} customClass={`${styles.entitySelect}`}></Input>
        <Button
          variant="secondary"
          customClass={`${styles.actionButton} ${styles.inspectButton}`}
          onClick={onClickInspect}
          disabled={isInspectDisabled}
        >
          Inspect
        </Button>
      </div>
      <div className={""}>
        {/*<Button variant="secondary" disabled={true} customClass={styles.actionButton} onClick={onClickApply}>
          Apply
        </Button>
        <Select
          label="Actions: "
          key={`FM-Select-action`}
          value={routeSelected}
          onChange={onChangeSelectedFilterRoute}
          options={routeOptions}
          multiple={false}
          customClass={`${styles.entitySelect}`}
        /> */}
      </div>
    </div>
  );
}
