import {LinearProgress} from "@material-ui/core";
import {AlertMessage} from "@vacasa/react-components-lib";
import _ from "lodash";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import JsonBox from "../json-box/JsonBox";
import styles from "./ChangeAuditTabs.module.scss";

export default function FullCode() {
  const {latestChange, isLastChangeLoading} = useSelector((state: RootState) => state.changes);
  if (isLastChangeLoading) return <LinearProgress className="loading-line" />;
  return (
    <div className={styles.container}>
      {_.isNil(latestChange) || latestChange === "" ? (
        <AlertMessage customClass="warning-alert" type="warning">
          No code found
        </AlertMessage>
      ) : (
        <JsonBox title="Json" code={latestChange} />
      )}
    </div>
  );
}
