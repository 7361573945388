import styles from "./Body.module.scss";
import {Route, Routes, useLocation} from "react-router-dom";
import Show from "../show/Show";
import {NavRoutes} from "../../types/Routes";
import {Hydration, Fetch, Inspect} from "../";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
export default function Body() {
  let location = useLocation();
  let state = location.state as {backgroundLocation?: Location};

  const HomePage = () => (
    <div>
      <p>Welcome to the Eventhub manager.</p>
      <p>Please select a Entity type, id and version </p>
    </div>
  );

  const {routeSelected} = useSelector((state: RootState) => state.filters);
  const getName = (route: string) => {
    if (route === NavRoutes.FETCH) return "Fetch";
    else if (route === NavRoutes.SHOW_SCHEMME) return "Show";
    else if (route === NavRoutes.HYDRATION) return "Hydration";
    else if (route === NavRoutes.INSPECT) return "Inspect";
    return "Welcome";
  };
  return (
    <div className={styles.container}>
      <p className={styles.title}>{getName(location.pathname.replace("/", ""))}</p>
      <div className={styles.modalBody}>
        <Routes location={state?.backgroundLocation || location}>
          <Route index element={<HomePage />} />
          <Route path={NavRoutes.SHOW_SCHEMME} element={<Show />} />
          <Route path={NavRoutes.INSPECT} element={<Inspect />} />
          <Route path={NavRoutes.HYDRATION} element={<Hydration />} />
          <Route path={NavRoutes.FETCH} element={<Fetch />} />
        </Routes>
      </div>
    </div>
  );
}
