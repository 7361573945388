import {useState} from "react";
import {Tabs} from "@vacasa/react-components-lib";
import {FullCode, TableChanges} from "../../components";
import styles from "./Show.module.scss";
import "../../index.scss";

export default function Show() {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className={styles.container}>
      <Tabs
        customClass={styles.containerTabs}
        selected={selectedTab}
        tabs={[
          {
            id: "units",
            label: "Latest",
            component: <FullCode />,
          },
          {
            id: "cohorts",
            label: "Changes",
            component: <TableChanges />,
          },
        ]}
        onChange={(index) => setSelectedTab(index)}
      />
    </div>
  );
}
