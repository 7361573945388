import React, {Component} from "react";
import styles from "./Navbar.module.scss";
import {Icon} from "@vacasa/react-components-lib";
import UserBox from "../user-box/UserBox";
import vacasaLogo from "../../assets/images/iso-vacasa.svg";
import jwt_decode from "jwt-decode";
export default function Navbar() {
  //   static getAuthenticatedUser(): {email: string; name: string; given_name: string; family_name: string} {
  //     const token = localStorage.getItem("idToken");
  //     return token && jwt_decode(token);
  // }

  const token = localStorage.getItem("idToken");
  let userName = "";
  if (token) {
    const user: any = jwt_decode(token);
    userName = user.name;
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleBox}>
        <img src={vacasaLogo} alt="Your SVG" />
        <p className={styles.title}>Eventhub manager</p>
      </div>
      <UserBox user={userName} />
    </div>
  );
}
