import _ from "lodash";
import {ImplicitFlowService} from "./base/implicit-flow.service";
import {FrontendConfiguration} from "@common/configuration";
export class EntityService extends ImplicitFlowService {
    private static service: EntityService;

    constructor() {
        super(FrontendConfiguration.eventhubUiBackendConfiguration().url);
    }

    public static getInstance(): EntityService {
        if (!EntityService.service) {
            EntityService.service = new EntityService();
        }
        return EntityService.service;
    }

    public async getEntities(): Promise<Array<any>> {
        try {
            const axios = await this.axiosInstance();
            const response = await axios.get(`${this.apiUrl}/api/v1/entities`);
            if (response.data.statusCode !== 200) {
                throw response.data.body;
            }
            return response.data.body.data;
        } catch (e: any) {
            console.error("Error: ", {e});
            throw e;
        }
    }
}
