import styles from "./UserBox.module.scss";
import PersonIcon from "@mui/icons-material/Person";

export default function UserBox(user: any) {
  return (
    <div className={styles.containerUser}>
      <PersonIcon color="inherit" />
      <p className={styles.title}>{user?.name}</p>
    </div>
  );
}
