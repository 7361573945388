import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import DefaultTheme from "@vacasa/react-components-lib/src/themes/default";
import EventhubUI from "./EventhubUI";
import reportWebVitals from "./reportWebVitals";
import {ImplicitFlow, ThemeProvider} from "@vacasa/react-components-lib";
import "./index.scss";
import {Provider} from "react-redux";
import {store} from "./store/store";
import {BrowserRouter} from "react-router-dom";
import {FrontendConfiguration} from "@common/configuration";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

if (FrontendConfiguration.environment() === FrontendConfiguration.LOCAL) {
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={DefaultTheme}>
        <Provider store={store}>
          <BrowserRouter>
            <EventhubUI />
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  );
} else {
  root.render(
    <React.StrictMode>
      <ImplicitFlow
        clientId={FrontendConfiguration.idpClientId()}
        redirectUrl={FrontendConfiguration.idpRedirectUri()}
        audience={FrontendConfiguration.idpManagementServiceConfig().url || ""}
        scopes="eventhub:read"
        directoryHint="onelogin"
        env={FrontendConfiguration.environment()}
        followRedirect={true}
      >
        <ThemeProvider theme={DefaultTheme}>
          <Provider store={store}>
            <BrowserRouter>
              <EventhubUI />
            </BrowserRouter>
          </Provider>
        </ThemeProvider>
      </ImplicitFlow>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
