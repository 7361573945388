import React from "react";
import {fetchChanges} from "../../../store/reducers/changes";
import {setSelectedDate} from "../../../store/reducers/filters";
import {RootState, useAppDispatch} from "../../../store/store";
import {useSelector} from "react-redux";
import styles from "../ChangeAuditTabs.module.scss";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {getDateFromTimestamp} from "../../../utils/helpers";
import {LinearProgress} from "@mui/material";
import {AlertMessage, ThemeProvider} from "@vacasa/react-components-lib";
import _ from "lodash";

const DayButton = ({dayData, onClick}: any) => {
  const {selectedDate} = useSelector((state: RootState) => state.filters);

  const {id} = dayData;
  const [day, time] = getDateFromTimestamp(id).split(" ");
  return (
    <li className={`${styles.dateButtonContainer} ${selectedDate === id ? styles.selectedDate : ""}`}>
      <div className={styles.data} onClick={() => onClick(dayData)}>
        <span className={styles.dateHeader}>{day}</span>
        <span className={styles.dateHeader}>{time}</span>
      </div>
    </li>
  );
};

export default function DaysList() {
  const dispatch = useAppDispatch();

  const onClickDay = (changeDay: any) => {
    dispatch(setSelectedDate(changeDay));
    dispatch(fetchChanges(changeDay));
  };

  const {currDateChanges, isInspectLoading, isFetchingChanges} = useSelector((state: RootState) => state.changes);
  return (
    <div className={styles.colLeft}>
      <div className={styles.header}>
        <CalendarTodayIcon />
        <p>Change audit</p>
      </div>
      <div className={styles.barContainer}>
        {isInspectLoading ? (
          <LinearProgress className="loading-line" />
        ) : _.isNil(currDateChanges) || currDateChanges.length == 0 ? (
          <AlertMessage customClass="warning-alert" type="warning">
            No dates with changes found
          </AlertMessage>
        ) : (
          <ul className={styles.bar}>
            {currDateChanges.map((dayData: any, index: number) => (
              <DayButton key={`changes-${index}-${dayData.date}`} dayData={dayData} onClick={() => onClickDay(dayData)} />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
