import React from "react";
import styles from "./JsonBox.module.scss";
export default function JsonBox({title, code}: any) {
  return (
    <div className={styles.jsonBox}>
      <div className={styles.headerJsonBox}>{title}</div>
      <div className={styles.bodyJsonBox}>
        <pre>{JSON.stringify(code, null, 4)}</pre>
      </div>
    </div>
  );
}
