export class FrontendConfiguration {
    public static dummyConfiguration(): boolean {
        return false;
    }

    static readonly LOCAL = "local";
    static readonly DEVELOPMENT = "dev";
    static readonly STAGING = "stage";
    static readonly PRODUCTION = "prod";

    public static environment(): "local" | "stage" | "prod" | "dev" {
        if (FrontendConfiguration.appUrl().includes("localhost")) {
            return this.LOCAL;
        } else if (FrontendConfiguration.appUrl().includes("vacasadev.")) {
            return this.DEVELOPMENT;
        } else if (FrontendConfiguration.appUrl().includes("vacasastage.")) {
            return this.STAGING;
        } else if (FrontendConfiguration.appUrl().includes("vacasa.")) {
            return this.PRODUCTION;
        }

        return this.LOCAL;
    }

    public static appUrl(): string {
        const {REACT_APP_SPA_URL} = process.env;
        return REACT_APP_SPA_URL!;
    }

    public static vacasaIdpDomain(): string {
        const {REACT_APP_VACASA_IDP_DOMAIN} = process.env;
        return REACT_APP_VACASA_IDP_DOMAIN!;
    }

    public static idpRedirectUri(): string {
        const {REACT_APP_IDP_REDIRECT_URI} = process.env;
        return REACT_APP_IDP_REDIRECT_URI!;
    }

    public static idpClientId(): string {
        const {REACT_APP_IDP_CLIENT_ID} = process.env;
        return REACT_APP_IDP_CLIENT_ID!;
    }

    public static eventhubUiBackendConfiguration(): any {
        const {REACT_APP_EVENTHUB_UI_BACKEND_URL} = process.env;
        return {url: REACT_APP_EVENTHUB_UI_BACKEND_URL!};
    }

    public static idpManagementServiceConfig() {
        return {url: process.env.REACT_APP_EVENTHUB_UI_URL!};
    }
}
