import {ImplicitFlowService} from "./base/implicit-flow.service";
import {FrontendConfiguration} from "@common/configuration";
import _ from "lodash";

export class ChangesService extends ImplicitFlowService {
    constructor() {
        super(FrontendConfiguration.eventhubUiBackendConfiguration().url);
    }

    private static service: ChangesService;
    public static getInstance(): ChangesService {
        if (!ChangesService.service) {
            ChangesService.service = new ChangesService();
        }
        return ChangesService.service;
    }

    public async getChangeDates(entity, id, version) {
        const axios = await this.axiosInstance();
        const response = await axios.get(`${this.apiUrl}/api/v1/changeDatesByUnitId/${version}/${entity}/${id}`);
        if (_.isNil(response.data.body.data)) return [];
        return response.data.body.data.map((dateObject) => ({
            date: dateObject.attributes.date,
            id: dateObject.attributes.id,
        }));
    }

    public async getChanges(version, entity, entity_id, created_at, change_id) {
        const axios = await this.axiosInstance();
        const response = await axios.get(`${this.apiUrl}/api/v1/changes/${version}/${entity}/${entity_id}/${created_at}/${change_id}`);
        return response.data.body.data;
    }

    public async getLatestChange(entity, id, version) {
        try {
            const axios = await this.axiosInstance();
            const response = await axios.get(`${this.apiUrl}/api/v1/changes/latest/${version}/${entity}/${id}`);
            return response.data.body.data;
        } catch (e) {
            throw e;
        }
    }
}
