//TODO: Single file
import React from "react";

import styles from "../ChangeAuditTabs.module.scss";
import DownloadIcon from "@mui/icons-material/Download";
import {Input} from "@vacasa/react-components-lib";
import {Button} from "@vacasa/react-components-lib";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../store/store";
import {filterChanges, setChangesFilter} from "../../../store/reducers/changes";
import DatePicker from "react-multi-date-picker";

import * as _ from "lodash";
import "./changes.css";

const FilterChanges = () => {
  // Date picker

  //TODO: To another component.
  const exportData = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(filteredChanges))}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "data.json";
    link.click();
  };

  const {filteredChanges, changesFilter} = useSelector((state: RootState) => state.changes);
  const [startDate, endDate] = changesFilter.dateRange || [];
  const [value, setValue] = React.useState("");
  const dispatch = useAppDispatch();
  const onChangeFilter = (field: string, value: any) => {
    dispatch(setChangesFilter({[field]: value}));
  };

  const onChangeDate = (value: any) => {
    onChangeFilter("dateRange", value);
  };

  const onFilterChanges = () => {
    dispatch(filterChanges());
  };
  return (
    <div className={styles.changesFilter}>
      <div className={styles.filterOptions}>
        <div key={"filter-changes-date"} className={styles.datePicker}>
          <p>Date</p>
          {/* <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update: any) => {
              onChangeDate(update);
            }}
            isClearable={true}
          /> */}

          <DatePicker value={value} onChange={onChangeDate} range />
        </div>

        <Input
          key={"filter-changes-name"}
          label="Field name"
          value={changesFilter.fieldNameFilter || ""}
          onChange={(e) => {
            onChangeFilter("fieldNameFilter", e.target.value);
          }}
          disabled={false}
          type={"text"}
          customClass={`${styles.entitySelect}`}
        />

        <Button variant="info" customClass={styles.button} onClick={onFilterChanges}>
          Search
        </Button>
      </div>
      <DownloadIcon className={styles.downloadButton} onClick={exportData} />
    </div>
  );
};

export default FilterChanges;
