import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import {TooltipType} from "@common/types";
import {EntityService} from "@common/frontend-services";
import _ from "lodash";
import {NavRoutes} from "../../types/Routes";
import {RootState} from "../store";
export interface EntityState {
    isLoading: boolean;
    entities: Array<TooltipType>;
    versions: Array<TooltipType>;
    groupedEntities: Array<any>;
    selectedEntity: string | null;
    selectedId: string;
    routeSelected: string | null;
    isInspectDisabled: boolean;
    isApplyDisabled: boolean;
    selectedVersion: string;
    selectedDate: string;
}

const initialState: EntityState = {
    isLoading: false,
    groupedEntities: [],
    entities: [],
    versions: [],
    selectedVersion: "",
    selectedEntity: "",
    selectedId: "",
    selectedDate: "",
    routeSelected: NavRoutes.SHOW_SCHEMME,
    isInspectDisabled: true,
    isApplyDisabled: false,
};

export const fetchEntities = createAsyncThunk("entities/fetchEntities", async () => {
    const entityService = EntityService.getInstance();
    const response = await entityService.getEntities();
    return response;
});

export const setSelectedEntity = createAsyncThunk("entities/setSelectedEntity", async (entity: string, {getState}) => {
    const state = getState() as RootState;
    const {groupedEntities} = state.filters;
    return {entity, groupedEntities};
});

const isInspectDisabled = (state: EntityState): boolean => {
    const {selectedId, selectedEntity, selectedVersion} = state;
    return (
        _.isNil(selectedId) ||
        _.isNaN(selectedId) ||
        _.isNil(selectedEntity) ||
        _.isNil(selectedVersion) ||
        selectedId === "" ||
        selectedEntity === "" ||
        selectedVersion === ""
    );
};

const getEntities = (groups: Array<any>, selectedVersion: string): Array<string> => {
    const entitiesGroup = groups.find((group) => {
        return group.attributes.entity === selectedVersion;
    });
    return entitiesGroup.attributes.versions;
};

const EntitySlice = createSlice({
    name: "dummySlice",
    initialState,
    reducers: {
        setIsLoading: (state) => {
            state.isLoading = true;
        },
        setIsNotLoading: (state) => {
            state.isLoading = false;
        },
        setSelectedVersion(state, action: PayloadAction<string>) {
            state.isInspectDisabled = isInspectDisabled(state);
            state.selectedVersion = action.payload;
        },
        setSelectedId(state, action: PayloadAction<string>) {
            state.selectedId = action.payload;
            state.isInspectDisabled = isInspectDisabled(state);
        },
        setSelectedRoute(state, action: PayloadAction<string>) {
            state.routeSelected = action.payload;
        },
        setSelectedDate(state, {type, payload}) {
            state.selectedDate = payload.id;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchEntities.pending, (state, {payload}) => {
            state.isLoading = true;
        });
        builder.addCase(fetchEntities.fulfilled, (state, {payload}) => {
            state.groupedEntities = payload as Array<any>;

            //Entity -> version
            //Version -> entity
            const initialEntity = payload[0].attributes;
            state.selectedEntity = initialEntity.entity;
            state.versions = initialEntity.versions.map((version: string) => ({
                value: version,
                display: version,
                tooltip: version,
            }));
            state.entities = payload.map((version) => ({
                value: version.attributes.entity,
                display: version.attributes.entity,
                tooltip: "",
            }));
            state.isLoading = false;
        });
        builder.addCase(setSelectedEntity.pending, (state, {payload}) => {
            state.isLoading = true;
        });

        builder.addCase(setSelectedEntity.fulfilled, (state, {payload}) => {
            const selectedEntity: string = payload.entity;
            state.selectedEntity = selectedEntity;

            const versions = getEntities(payload.groupedEntities, selectedEntity);
            state.versions = versions.map((entity: string) => ({
                value: entity,
                display: entity,
                tooltip: entity,
            }));
            state.isLoading = false;
        });
    },
});

export const {setIsLoading, setIsNotLoading, setSelectedVersion, setSelectedId, setSelectedRoute, setSelectedDate} = EntitySlice.actions;

export default EntitySlice.reducer;
