import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ChangesService} from "@common/frontend-services";
import {RootState} from "../store";
import {isBefore} from "date-fns";
import {Changes, DateChange} from "@common/types";
import {filterObjectKeys, isSameOrAfterDay, isSameOrBeforeDay} from "../../utils/helpers";
import * as _ from "lodash";
export interface ChangesFilterState {
    dateRange: Array<any>;
    fieldNameFilter: string | null;
}

export interface ChangesState {
    isFetchingChanges: boolean;
    isInspectLoading: boolean;
    isLastChangeLoading: boolean;
    currDateChanges: Array<DateChange>;
    dateChanges: Array<DateChange>;
    selectedChangeId: string;
    latestChange: any;
    selectedChangeDate: string;
    filteredChanges: Array<Changes> | null;
    selectedChanges: Array<Changes> | null;
    changesFilter: ChangesFilterState;
}

const initialState: ChangesState = {
    isFetchingChanges: false,
    isInspectLoading: false,
    isLastChangeLoading: false,
    currDateChanges: [],
    selectedChangeDate: "",
    selectedChangeId: "",
    filteredChanges: null,
    selectedChanges: null,
    dateChanges: [],
    latestChange: "",
    changesFilter: {
        dateRange: [],
        fieldNameFilter: "",
    },
};

export const fetchInspectValuesAction = createAsyncThunk("changes/fetchInspectValues", async (_, {dispatch}) => {
    try {
        await dispatch(fetchChangeDates());
        await dispatch(fetchLatestValueFromEntity());
    } catch (e) {
        throw {message: "Error fetching Inspect initial values", error: e};
    }
});

export const fetchChangeDates = createAsyncThunk("changes/fetchChangeDates", async (filters, {getState}) => {
    try {
        const changesService = ChangesService.getInstance();
        const entityService = ChangesService.getInstance();
        const state = getState() as RootState;
        const {selectedEntity, selectedId, selectedVersion} = state.filters;
        const formattedChangeDates = await changesService.getChangeDates(selectedEntity || "", selectedId, selectedVersion || "");

        let latestChanges;
        if (!_.isNil(formattedChangeDates[0]?.date)) {
            const {date, id} = formattedChangeDates[0];
            const response = await entityService.getChanges(selectedVersion, selectedEntity, selectedId, date, id);
            if (!_.isNil(response)) latestChanges = response.map((change: any) => ({...change.attributes})) as Array<any>;
        }
        return {formattedChangeDates, latestChanges};
    } catch (e) {
        throw {message: "Error fetching the dates of a entity", error: e};
    }
});

export const fetchLatestValueFromEntity = createAsyncThunk("changes/fetchLatestValueFromEntity", async (filters, {getState}) => {
    try {
        const changesService = ChangesService.getInstance();
        const state = getState() as RootState;
        const {selectedEntity, selectedId, selectedVersion} = state.filters;
        const latestChange = await changesService.getLatestChange(selectedEntity || "", selectedId, selectedVersion || "");
        return latestChange;
    } catch (e) {
        throw {message: "Error fetching the latest Value of entity", error: e};
    }
});

export const fetchChanges = createAsyncThunk("changes/fetchChanges", async (data: any, {getState}) => {
    const {date, id} = data;
    const state = getState() as RootState;
    const {selectedEntity, selectedId, selectedVersion} = state.filters;
    const entityService = ChangesService.getInstance();
    if (_.isNil(selectedEntity)) {
        console.error("Entity is undefined");
        return [];
    }
    const response = await entityService.getChanges(selectedVersion, selectedEntity, selectedId, date, id);
    const returnValues = {
        changesArr: response.map((change: any) => ({...change.attributes})),
        selectedDate: id,
    };

    return returnValues;
});

//TODO: Move
const filterDateChanges = (dateChanges: Array<DateChange>, dateFilter: Array<any>) => {
    if (dateFilter.length > 0 && dateChanges.length > 0) {
        let endDate = new Date();
        const startDate = new Date(dateFilter[0]);

        if (dateFilter.length > 1) {
            endDate = new Date(dateFilter[1]);
        }
        return dateChanges.filter((item) => {
            const itemDate = new Date(item.date);

            return isSameOrAfterDay(itemDate, startDate) && isBefore(itemDate, endDate);
        });
    }
    return [];
};

const filterChangeAttributes = (changes: Array<Changes>, attributes: string) => {
    const attributesArr = attributes.split(",");
    let filteredChanges = [];

    if (attributes === "" || _.isEmpty(changes)) return changes;

    if (!_.isNaN(+changes[0].label)) {
        return changes.map((change) => {
            if (attributes.includes(change.label)) return change;
            if (!_.isNaN(+change.label)) {
                let filteredChange = {
                    old_value: filterObjectKeys(change.old_value, attributesArr),
                    new_value: filterObjectKeys(change.new_value, attributesArr),
                };
                return {...change, ...filteredChange};
            }
            return change;
        });
    }

    return changes.filter((change) => change.label.includes(attributes));
};

export const filterChanges = createAsyncThunk("changes/filterChanges", async (_, {getState}) => {
    const {changes} = getState() as RootState;
    const {dateRange, fieldNameFilter} = changes.changesFilter;
    const currChanges = filterChangeAttributes(changes.selectedChanges || [], fieldNameFilter || "");

    if (dateRange.length === 0) return {dateChanges: changes.dateChanges, currChanges};

    const dateChanges = filterDateChanges(changes.dateChanges, dateRange);
    return {dateChanges, currChanges};
    //.date  .id
});

const changesSlice = createSlice({
    name: "changesSlice",
    initialState,
    reducers: {
        isFetchingChanges: (state) => {
            state.isFetchingChanges = true;
        },
        setIsNotLoading: (state) => {
            state.isFetchingChanges = false;
        },
        setSelectedChangeDate(state, {type, payload}) {
            state.selectedChangeDate = payload.id;
        },
        setChangesFilter(state, {type, payload}) {
            state.changesFilter = {...state.changesFilter, ...payload};
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchChangeDates.pending, (state, {payload}) => {
            state.isInspectLoading = true;
            state.currDateChanges = [];
            state.dateChanges = [];
        });
        builder.addCase(fetchChangeDates.fulfilled, (state, {payload}) => {
            const {formattedChangeDates, latestChanges} = payload as {formattedChangeDates: Array<DateChange>; latestChanges: Array<Changes>};
            state.dateChanges = formattedChangeDates;
            //Filter with the actual values.
            state.currDateChanges = formattedChangeDates;
            state.selectedChangeDate = formattedChangeDates[0]?.id;
            state.selectedChanges = latestChanges;
            state.filteredChanges = filterChangeAttributes(latestChanges || [], state.changesFilter.fieldNameFilter || "");
            state.isInspectLoading = false;
        });
        builder.addCase(fetchLatestValueFromEntity.pending, (state, {payload}) => {
            state.latestChange = "";
            state.isLastChangeLoading = true;
        });
        builder.addCase(fetchLatestValueFromEntity.fulfilled, (state, {payload}) => {
            if (_.isNil(payload)) state.latestChange = "";
            else state.latestChange = payload.attributes.data;
            state.isLastChangeLoading = false;
        });

        builder.addCase(fetchChanges.pending, (state) => {
            state.isFetchingChanges = true;
        });
        builder.addCase(fetchChanges.fulfilled, (state, {payload}) => {
            const {changesArr, selectedDate} = payload as {changesArr: any; selectedDate: string};
            state.selectedChanges = changesArr;
            state.filteredChanges = filterChangeAttributes(changesArr || [], state.changesFilter.fieldNameFilter || "");
            state.isFetchingChanges = false;
        });

        builder.addCase(filterChanges.pending, (state) => {
            //state.isFetchingChanges = true;
            state.filteredChanges = initialState.filteredChanges;
        });
        builder.addCase(filterChanges.fulfilled, (state, {payload}) => {
            //TODO: Duplicate this field to persist all the changes.
            state.currDateChanges = payload.dateChanges;
            state.filteredChanges = payload.currChanges;
        });
        builder.addCase(fetchInspectValuesAction.pending, (state) => {
            state.isInspectLoading = true;
            state.isFetchingChanges = true;
            state.selectedChangeDate = initialState.selectedChangeDate;
            state.selectedChanges = initialState.selectedChanges;
            state.filteredChanges = initialState.filteredChanges;
            state.isLastChangeLoading = true;
        });
        builder.addCase(fetchInspectValuesAction.fulfilled, (state) => {
            state.isInspectLoading = false;
            state.isLastChangeLoading = false;
            state.isFetchingChanges = false;
        });
    },
});

export const {isFetchingChanges, setIsNotLoading, setSelectedChangeDate, setChangesFilter} = changesSlice.actions;

export default changesSlice.reducer;
