import React from "react";
import styles from "../ChangeAuditTabs.module.scss";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import {AlertMessage} from "@vacasa/react-components-lib";

import {RootState} from "../../../store/store";
import {useSelector} from "react-redux";
import _ from "lodash";
import {LinearProgress} from "@material-ui/core";
import DaysList from "./DaysList";
import FilterChanges from "./FilterChanges";

interface Column {
  id: "label" | "old_value" | "new_value";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {id: "label", label: "Name", minWidth: 170},
  {id: "old_value", label: "Value before the change", minWidth: 100},
  {
    id: "new_value",
    label: "Value after the change",
    minWidth: 170,

    format: (value: number) => value.toLocaleString("en-US"),
  },
];

export default function TableChanges() {
  const {filteredChanges, isFetchingChanges} = useSelector((state: RootState) => state.changes);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getColor = (type: string) => {
    if (type === "CREATED") {
      return styles.rowInserted;
    } else if (type === "DELETED") {
      return styles.rowDeleted;
    }
    return "";
  };

  const RenderTable = () => {
    const Loading = () => <LinearProgress className="loading-line" />;
    const NoChanges = () => (
      <AlertMessage customClass="warning-alert" type="warning">
        No changes found
      </AlertMessage>
    );
    return (
      <div className={styles.colRight}>
        <Paper sx={{width: "100%", overflow: "hidden", height: "100%"}}>
          <TableContainer sx={{maxHeight: 440}} className={styles.tableContainer}>
            {isFetchingChanges ? (
              <Loading />
            ) : _.isNil(filteredChanges) ? (
              <NoChanges />
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell key={`table-columns-${index}-${column.id}`} align={column.align} style={{minWidth: column.minWidth}}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredChanges &&
                    filteredChanges.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((change: any) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={`row-${change.label}-${change.type}`} className={getColor(change.type)}>
                          {columns.map((column, index) => {
                            const value = change[column.id];
                            return (
                              <TableCell key={`table-row-${page}-${change[column.label]}-${index}`} align={column.align}>
                                <span>{!_.isNil(value) && JSON.stringify(value, null, 4)}</span>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredChanges?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  };

  return (
    <div>
      <FilterChanges />
      <div className={styles.container}>
        <DaysList key={"date-changes"} />
        <RenderTable key={"table-changes"} />
      </div>
    </div>
  );
}
