import {configureStore} from "@reduxjs/toolkit";

import {useDispatch} from "react-redux";
import changes from "./reducers/changes";
import filters from "./reducers/filters";
export const store = configureStore({
    reducer: {
        filters: filters,
        changes: changes,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
