import {format} from "date-fns";
import {isSameDay, isAfter, isBefore} from "date-fns";

export const formatQueryParameters = (params: any, paramKey: string) => {
    return Object.keys(params)
        .map((key) => `${paramKey}[${key}]=${params[key]}`)
        .join("&");
};

export const getDateFromTimestamp = (timestamp: string) => {
    const date = new Date(parseInt(timestamp));
    const formattedDate = format(date, "MM-dd-yyyy HH:mm:ss");
    return formattedDate;
};

export const isSameOrAfterDay = (firstDate: Date, secondDate: Date): boolean => {
    return isSameDay(firstDate, secondDate) || isAfter(firstDate, secondDate);
};

export const isSameOrBeforeDay = (firstDate: Date, secondDate: Date): boolean => {
    return isSameDay(firstDate, secondDate) || isBefore(firstDate, secondDate);
};

export const filterObjectKeys = (rawObject: any, filterKeys: Array<string>) => {
    return Object.keys(rawObject)
        .filter((key) => filterKeys.includes(key))
        .reduce((obj: any, key) => {
            obj[key] = rawObject[key];
            return obj;
        }, {});
};
